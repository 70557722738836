const TEMPLATES_BASE_URL =
  "https://storage.googleapis.com/pepeonblast/png-templates/";

export const templates = Array.from({ length: 29 }, (_, index) => ({
  id: index + 1,
  category: ["template"],
  front: `${TEMPLATES_BASE_URL}${index + 1}.png`,
}));

export default templates;
