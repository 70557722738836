import React from "react";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import Roadmap from "../../components/Roadmap";
import About from "../../components/About"; // Import the About component
import Tokenomics from "../../components/Tokenomics"; // Import the Tokenomics component
import HowToBuy from "../../components/HowToBuy";
import "./Home.css";

function Home() {
  return (
    <div className="home">
      <Header />
      <About /> {/* Add the About component */}
      <Tokenomics /> {/* Add the Tokenomics component */}
      <HowToBuy />
      <Footer />
    </div>
  );
}

export default Home;
