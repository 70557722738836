const BRANDING_BASE_URL =
  "https://storage.googleapis.com/pepeonblast/png-branding/";

export const branding = [
  {
    id: 1,
    category: ["branding"],
    front: `${BRANDING_BASE_URL}1.png`,
  },
  {
    id: 2,
    category: ["branding"],
    front: `${BRANDING_BASE_URL}2.png`,
  },
  {
    id: 3,
    category: ["branding"],
    front: `${BRANDING_BASE_URL}3.png`,
  },
  {
    id: 4,
    category: ["branding"],
    front: `${BRANDING_BASE_URL}4.png`,
  },
  {
    id: 5,
    category: ["branding"],
    front: `${BRANDING_BASE_URL}5.png`,
  },
  {
    id: 6,
    category: ["branding"],
    front: `${BRANDING_BASE_URL}6.png`,
  },
  // Add more branding elements as needed
];

export default branding;
