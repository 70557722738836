import Typewriter from "typewriter-effect";
import React, { useState, useEffect } from "react";
import prettyboy from "../assets/prettyboy.webp";
import ser from "../assets/ser.webp";
import jeff from "../assets/jeff.webp";
import benKen from "../assets/ben-ken.webp";
import jeeter from "../assets/jeeter.webp";
import papaPapajr from "../assets/papa-papajr.webp";
import kol from "../assets/kol.webp";
import tgIcon from "../assets/tg.webp";
import dexscreenerIcon from "../assets/dexscreener.webp";
import dextoolsIcon from "../assets/dextools.webp";
import twitterIcon from "../assets/twitter.webp";
import "./Header.css";

function Header() {
  const [isTypingComplete, setIsTypingComplete] = useState(false);

  useEffect(() => {
    if (isTypingComplete) {
      document.querySelector(".typewriter-text").classList.add("bounce");
    }
  }, [isTypingComplete]);

  const handleBuyButtonClick = () => {
    window.open(
      "https://app.thruster.finance/?token1=0x2A4e89dffb7960b8D09da58A6d04f7916069E6f3",
      "_blank",
      "noopener,noreferrer"
    );
  };

  return (
    <div className="header-container">
      <div className="pepeonblast-container">
        <span className="pepeonblast-title">pepe</span>
        <span className="default-font">&nbsp;</span>
        <span className="default-font">&nbsp;</span>
        <span className="pepeonblast-title">on</span>
        <span className="default-font">&nbsp;</span>
        <span className="default-font">&nbsp;</span>
        <span className="pepeonblast-title">blast</span>
      </div>
      <div className="header-content">
        <div className="typewriter-text">
          <Typewriter
            options={{
              strings: ["A community of chads, by chads."],
              autoStart: true,
              loop: false,
              delay: 40,
              cursor: "",
              deleteSpeed: Infinity,
              onComplete: () => setIsTypingComplete(true),
            }}
          />
        </div>
        <div className="action-container">
          <div className="socials-container">
            <a
              href="https://t.me/pepecoinblast"
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className="icon wiggle-hover">
                <img src={tgIcon} alt="Telegram" className="icon-img" />
              </div>
            </a>
            <a
              href="https://dexscreener.com/blast/0x35a5f5d3cafbb12d7aad2c85a136b6d2334c635e"
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className="icon wiggle-hover">
                <img
                  src={dexscreenerIcon}
                  alt="Dexscreener"
                  className="icon-img"
                />
              </div>
            </a>
            <a
              href="https://www.dextools.io/app/en/blast/pair-explorer/0x35a5f5d3cafbb12d7aad2c85a136b6d2334c635e?t=1719240097533"
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className="icon wiggle-hover">
                <img src={dextoolsIcon} alt="Dextools" className="icon-img" />
              </div>
            </a>
            <a
              href="https://x.com/pepecoinblast"
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className="icon wiggle-hover">
                <img src={twitterIcon} alt="Twitter" className="icon-img" />
              </div>
            </a>
          </div>
          <button
            className="buy-button wiggle-hover"
            onClick={handleBuyButtonClick}
          >
            Buy
          </button>
        </div>
      </div>
      <div className="characters">
        <img
          src={prettyboy}
          alt="Prettyboy"
          className="character prettyboy fade-in"
        />
        <img src={ser} alt="Ser" className="character ser fade-in" />
        <img src={jeff} alt="Jeff" className="character jeff fade-in" />
        <img src={benKen} alt="Ben Ken" className="character benKen fade-in" />
        <img src={jeeter} alt="Jeeter" className="character jeeter fade-in" />
        <img
          src={papaPapajr}
          alt="Papa Papajr"
          className="character papaPapajr fade-in"
        />
        <img src={kol} alt="Kol" className="character kol fade-in" />
      </div>
    </div>
  );
}

export default Header;
