import React from "react";
import "./HowToBuy.css";
import step1Image from "../assets/step1.webp";
import step2Image from "../assets/step2.webp";
import step3Image from "../assets/step3.webp";

const HowToBuy = () => {
  return (
    <div className="how-to-buy-container">
      <p className="how-to-buy-title">How To Buy</p>
      <div className="step">
        <img className="step-image" src={step1Image} alt="Step 1" />
        <div className="step-description">
          <p>
            <a
              href="https://docs.blast.io/using-blast"
              target="_blank"
              rel="noopener noreferrer"
              className="custom-link"
            >
              Add Blast Network
            </a>
          </p>
        </div>
      </div>
      <div className="step">
        <img className="step-image" src={step2Image} alt="Step 2" />
        <div className="step-description">
          <p>
            Bridge to Blast:
            <br />
            <a
              href="https://orbiter.finance"
              target="_blank"
              rel="noopener noreferrer"
              className="custom-link"
            >
              Using Orbiter
            </a>
            <br />
            <a
              href="https://relay.link"
              target="_blank"
              rel="noopener noreferrer"
              className="custom-link"
            >
              Using Relay
            </a>
          </p>
        </div>
      </div>
      <div className="step">
        <img className="step-image" src={step3Image} alt="Step 3" />
        <div className="step-description">
          <p>
            <a
              href="https://app.thruster.finance/?token2=0x2A4e89dffb7960b8D09da58A6d04f7916069E6f3"
              target="_blank"
              rel="noopener noreferrer"
              className="custom-link"
            >
              Buy on Thruster Swap
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default HowToBuy;
