import React from "react";
import { useWallet } from "../../contexts/WalletProvider";
import GlitchClip from "react-glitch-effect/core/GlitchClip";
import GlitchText from "react-glitch-effect/core/GlitchText";
import styled, { keyframes } from "styled-components";
import Matrix from "../../components/Matrix";
import "./Landing.css";
import landingImage from "../../assets/earn.webp";

const glow = (color) => keyframes`
  0% {
    box-shadow: 0 0 5px ${color};
  }
  100% {
    box-shadow: 0 0 15px ${color};
  }
`;

const easeIn = keyframes`
  0% {
    opacity: 0;
    transform: scale(0.8);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
`;

const appear = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const TextContainer = styled.div`
  position: absolute;
  color: #ffbb01;
  background: rgba(0, 0, 0, 0.5);
  padding: 1em 2em;
  border-radius: 25px;
  border: 1px solid #ffbb01;
  padding: 1.5em 1em; /* Reduced padding */
  font-size: 1.5rem; /* Adjusted for balance */
  max-width: 80%; /* Ensures it doesn't exceed container width */
  font-family: "KGHappySolid";
  white-space: nowrap;
  cursor: pointer;
  transition: transform 0.3s ease;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20vw;
  height: 5vh;
  opacity: 0; /* Initially hidden */
  animation: ${appear} 0.5s ease-in forwards;
  animation-delay: 1s; /* Delay to match the image animation */

  @media (max-width: 768px) {
    width: 25vw;
    height: 4vh;
    font-size: 1em;
  }
  @media (max-width: 512px) {
    width: 30vw;
    height: 3.5vh;
    font-size: 0.8em;
  }
`;

const EnterText = styled(TextContainer)`
  top: 65%;
  right: 25%;
  transform: translate(50%, 50%);
  animation: ${glow("#ff0000")} 0.5s infinite alternate, ${appear} 0.5s ease-in forwards;
  animation-delay: 1s; /* Delay to match the image animation */
  background-color: red;
`;

const DoNotText = styled(TextContainer)`
  top: 65%;
  left: 25%;
  transform: translate(-50%, 50%);
  animation: ${glow("#0000ff")} 0.5s infinite alternate, ${appear} 0.5s ease-in forwards;
  animation-delay: 1s; /* Delay to match the image animation */
  background-color: blue;
`;

const Landing = () => {
  const { walletAddress } = useWallet();

  return (
    <div className="landing-container">
      <div className="image-container">
        <img src={landingImage} alt="Landing" className="landing-image" />
        <EnterText onClick={() => (window.location.href = "/home")}>
          <GlitchClip>
            <GlitchText>Escape the Matrix</GlitchText>
          </GlitchClip>
        </EnterText>
        <DoNotText onClick={() => (window.location.href = "/careers")}>
          <GlitchClip>
            <GlitchText>Get a "Real Job"</GlitchText>
          </GlitchClip>
        </DoNotText>
      </div>
      <Matrix />
    </div>
  );
};

export default Landing;
