import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import "./Navbar.css";

const Navbar = () => {
  const location = useLocation();

  useEffect(() => {
    const links = document.querySelectorAll(".navbar-link");

    links.forEach((link) => {
      const text = link.getAttribute("data-text");
      link.innerHTML = text
        .split("")
        .map(
          (char) =>
            `<span class="char">${char === " " ? "&nbsp;" : char}</span>`
        )
        .join("");
    });
  }, [location]);

  const getNavbarStyle = () => {
    if (location.pathname === "/memes") {
      return { backgroundColor: "rgb(32,32,32,0.5)" };
    } else if (location.pathname === "/home") {
      return { backgroundColor: "rgb(255,233,174,0.3)" };
    } else {
      return { backgroundColor: "transparent" };
    }
  };

  // Do not render the navbar if the current path is "/"
  if (location.pathname === "/") {
    return null;
  }

  return (
    <nav className="navbar" style={getNavbarStyle()}>
      <div className="navbar-center">
        <ul className="navbar-list">
          <li className="navbar-item">
            <Link to="/home" className="navbar-link" data-text="HOME">
              HOME
            </Link>
          </li>
          <li className="navbar-item">
            <Link to="/memes" className="navbar-link" data-text="MEMES">
              MEMES
            </Link>
          </li>
        </ul>
      </div>
      <div className="navbar-right">
      </div>
    </nav>
  );
};

export default Navbar;
