import React, { useState } from "react";
import "./Careers.css";
import karenImg from "../../assets/karen.webp";
import cashierImg from "../../assets/programmer.webp";
import cleanerImg from "../../assets/cleaner.webp";
import burgerFlipperImg from "../../assets/Burger_flipper.webp";

function Careers() {
  const [selectedJob, setSelectedJob] = useState(null);
  const [formData, setFormData] = useState({ name: "", email: "" });
  const [submitted, setSubmitted] = useState(false);

  const jobs = [
    {
      title: "Manager",
      img: karenImg,
      description:
        "Our Karen ensures every minor inconvenience becomes a major event.",
    },
    {
      title: "Master Hacker",
      img: cashierImg,
      description: "Can hack your WiFi just to save on their data plan.",
    },
    {
      title: "Cleaner",
      img: cleanerImg,
      description:
        "Specializes in making messes disappear...and we don't ask how.",
    },
    {
      title: "Burger Flipper",
      img: burgerFlipperImg,
      description:
        "Turns flipping burgers into an art form that even Gordon Ramsay would envy.",
    },
  ];

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch("https://pepeonblast-backend.onrender.com/apply", { // Update this URL to your deployed backend URL
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ ...formData, job: selectedJob }),
      });
      if (response.ok) {
        setSubmitted(true);
      } else {
        console.error("Failed to submit application");
      }
    } catch (error) {
      console.error("Error submitting application", error);
    }
  };

  const closeModal = () => {
    setSelectedJob(null);
    setFormData({ name: "", email: "" });
    setSubmitted(false);
  };

  return (
    <div className="careers-page">
      <div className="careers-container">
        <p className="title">Get A Real Job</p>
        <div className="job-cards">
          {jobs.map((job) => (
            <div
              key={job.title}
              className="job-card"
              onClick={() => setSelectedJob(job.title)}
            >
              <div className="job-image-container">
                <img src={job.img} alt={job.title} className="job-image" />
              </div>
              <div className="job-content">
                <p className="job-title">{job.title}</p>
                <p className="job-description">{job.description}</p>
              </div>
            </div>
          ))}
        </div>
        {selectedJob && (
          <div className="modal-backdrop" onClick={closeModal}>
            <div className="modal-content" onClick={(e) => e.stopPropagation()}>
              {submitted ? (
                <div className="modal-message">
                  <p>You have successfully applied for {selectedJob} position at McPepe! Attaboy</p>
                  <button onClick={closeModal}>Close</button>
                </div>
              ) : (
                <form onSubmit={handleSubmit}>
                  <p className="modal-title">Apply for {selectedJob}</p>
                  <input
                    type="text"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    placeholder="Your Name"
                    required
                  />
                  <input
                    type="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    placeholder="Your Email"
                    required
                  />
                  <button type="submit">Submit</button>
                  <button type="button" onClick={closeModal}>Cancel</button>
                </form>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default Careers;
