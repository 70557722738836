import React, { useEffect, useRef } from "react";
import "./Matrix.css";

const Matrix = () => {
  const canvasRef = useRef(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");

    const resizeCanvas = () => {
      canvas.width = window.innerWidth;
      canvas.height = window.innerHeight;
    };

    resizeCanvas();

    const columns = Math.floor(canvas.width / 25); // Number of columns
    const letters = Array(columns).fill(0); // Array to store the y-coordinate of each column
    const fontSize = 25; // Font size
    const colors = ["#ffbb01", "#ffe9ae", "#FDFA54", "#202020"]; // Array of colors for gradient effect

    const draw = () => {
      ctx.fillStyle = "rgba(0, 0, 0, 0.07)";
      ctx.fillRect(0, 0, canvas.width, canvas.height + 100);

      ctx.font = `${fontSize}px arial`;

      letters.forEach((y, index) => {
        const text = String.fromCharCode(0x30a0 + Math.random() * 96);
        const x = index * fontSize;

        // Apply gradient color
        const gradient = ctx.createLinearGradient(
          0,
          0,
          canvas.width,
          canvas.height
        );
        gradient.addColorStop(
          0,
          colors[Math.floor(Math.random() * colors.length)]
        );
        gradient.addColorStop(
          1,
          colors[Math.floor(Math.random() * colors.length)]
        );

        ctx.fillStyle = gradient;
        ctx.fillText(text, x, y);

        if (y > canvas.height && Math.random() > 0.975) {
          letters[index] = 0;
        } else {
          letters[index] = y + fontSize;
        }
      });
    };

    const interval = setInterval(draw, 33);

    window.addEventListener("resize", resizeCanvas);

    return () => {
      clearInterval(interval);
      window.removeEventListener("resize", resizeCanvas);
    };
  }, []);

  return <canvas ref={canvasRef} className="matrix-canvas" />;
};

export default Matrix;
