import React from "react";
import Marquee from "react-fast-marquee";
import styled from "styled-components";
import "./Tokenomics.css";

import img1 from "../assets/10000000tokens.webp";
import img2 from "../assets/LPBurnt.webp";
import img3 from "../assets/OwnershipRenounced.webp";
import img4 from "../assets/CommunityOwned.webp";
import img5 from "../assets/10000000tokens2.webp";
import img6 from "../assets/LPBurnt2.webp";
import img7 from "../assets/OwnershipRenounced2.webp";
import img8 from "../assets/CommunityOwned2.webp";

const items = [
  { src: img1, alt: "10,000,000 tokens" },
  { src: img2, alt: "LP Burnt" },
  { src: img3, alt: "Ownership Renounced" },
  { src: img4, alt: "Community Owned" },
  { src: img5, alt: "10,000,000 tokens" },
  { src: img6, alt: "LP Burnt" },
  { src: img7, alt: "Ownership Renounced" },
  { src: img8, alt: "Community Owned" },
];

const TokenomicsItem = styled.div`
  background-color: #202020;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 15px;
  transform: rotateY(20deg);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  margin: 0 10px;
  overflow: hidden;
  max-width: 30vh;
  max-height: 30vh;
`;

const TokenomicsImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const Tokenomics = () => {
  return (
    <div className="tokenomics">
      <p className="tokenomics-title">Tokenomics</p>
      <Marquee gradient={false} speed={50}>
        {items.map((item, index) => (
          <TokenomicsItem key={index}>
            <TokenomicsImage src={item.src} alt={item.alt} />
          </TokenomicsItem>
        ))}
      </Marquee>
    </div>
  );
};

export default Tokenomics;
