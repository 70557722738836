import React, { useState, useEffect, useRef } from "react";
import "./About.css";
import Typewriter from "typewriter-effect";
import { gsap } from "gsap";
import Marquee from "react-fast-marquee";
import image1 from "../assets/1.webp";
import image2 from "../assets/2.webp";
import image3 from "../assets/3.webp";
import image4 from "../assets/4.webp";
import image5 from "../assets/5.webp";
import image6 from "../assets/6.webp";
import image7 from "../assets/7.webp";

const images = [
  {
    src: image1,
    description: "- w rizz <br> - never jeet <br>- can steal your girl",
    name: "pretty boy",
  },
  {
    src: image2,
    description: "- wife beater <br> - small balls <br>- cant steal your girl",
    name: "jeeter",
  },
  {
    src: image3,
    description:
      "- pump his bag or else <br> - beats you and leaves you <br>- first in first out",
    name: "kol",
  },
  {
    src: image4,
    description:
      "- never met a sell button <br> - goes hard on dips <br>- big dick energy",
    name: "ben & ken",
  },
  {
    src: image5,
    description:
      "- creates but never makes <br> - buy high sell low <br>- down bad",
    name: "jeff",
  },
  {
    src: image6,
    description:
      "- never pulls out <br> - protects his assets <br>- doesn't miss",
    name: "papa & papa jr",
  },
  {
    src: image7,
    description:
      "- terminally online <br> - slides in ur dms <br>- bell curve average iq",
    name: "ser",
  },
];

function About() {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [animating, setAnimating] = useState(false);
  const [isTypingVisible, setIsTypingVisible] = useState(false);
  const imageRef = useRef(null);
  const descriptionRef = useRef(null);

  useEffect(() => {
    images.forEach((image) => {
      const img = new Image();
      img.src = image.src;
    });
  }, []);

  const animationOut = { opacity: 0, ease: "power3.inOut" };
  const animationIn = { opacity: 1, ease: "power3.inOut" };

  const goToNextImage = () => {
    if (!animating) {
      setAnimating(true);
      gsap.to(imageRef.current, {
        ...animationOut,
        duration: 0.35,
        onComplete: () => {
          setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
          gsap.to(imageRef.current, {
            ...animationIn,
            duration: 0.35,
            onComplete: () => setAnimating(false),
          });
        },
      });
    }
  };

  const goToPreviousImage = () => {
    if (!animating) {
      setAnimating(true);
      gsap.to(imageRef.current, {
        ...animationOut,
        duration: 0.35,
        onComplete: () => {
          setCurrentIndex(
            (prevIndex) => (prevIndex - 1 + images.length) % images.length
          );
          gsap.to(imageRef.current, {
            ...animationIn,
            duration: 0.35,
            onComplete: () => setAnimating(false),
          });
        },
      });
    }
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "ArrowRight") {
        goToNextImage();
      } else if (event.key === "ArrowLeft") {
        goToPreviousImage();
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  useEffect(() => {
    const options = {
      threshold: 0.1,
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setIsTypingVisible(true);
        }
      });
    }, options);

    if (descriptionRef.current) {
      observer.observe(descriptionRef.current);
    }

    return () => {
      if (descriptionRef.current) {
        observer.unobserve(descriptionRef.current);
      }
    };
  }, []);

  useEffect(() => {
    setIsTypingVisible(false);
    const timer = setTimeout(() => {
      setIsTypingVisible(true);
    }, 500);

    return () => clearTimeout(timer);
  }, [currentIndex]);

  return (
    <div className="about">
      <p className="title-about">fams</p>
      <div className="main-container">
        <div className="left-section">
          <div className="image-box">
            <img
              ref={imageRef}
              src={images[currentIndex].src}
              alt="Current"
              className="current-image"
            />
          </div>
        </div>
        <div className="right-section">
          <div className="description-container">
            <p className="description-title">{images[currentIndex].name}</p>
            <div className="description-wrapper">
              <div className="description" ref={descriptionRef}>
                {isTypingVisible && (
                  <Typewriter
                    options={{
                      strings: images[currentIndex].description,
                      autoStart: true,
                      loop: false,
                      delay: 30,
                      cursor: " ",
                      deleteSpeed: Infinity,
                    }}
                  />
                )}
              </div>
            </div>
            <p className="image-index">
              {currentIndex + 1}/{images.length}
            </p>
          </div>
        </div>
      </div>
      <div className="bottom-section">
        <Marquee gradient={false}>
          {images.map((image, index) => (
            <div
              key={index}
              className="marquee-item"
              onClick={() => setCurrentIndex(index)}
            >
              <img src={image.src} alt={image.name} className="marquee-image" />
            </div>
          ))}
        </Marquee>
      </div>
    </div>
  );
}

export default About;
