import React, { useState, useEffect } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import Navbar from "../../components/Navbar";
import "./Memes.css";
import Footer from "../../components/Footer";
import memes from "../../constants/Memes"; // Import the memes array
import templates from "../../constants/Templates"; // Import the templates array
import branding from "../../constants/Branding"; // Import the branding array

const memeCategories = {
  memes: "just pepe",
  template: "template",
  branding: "branding",
};

const Memes = () => {
  const [selectedCategory, setSelectedCategory] = useState("memes");
  const [filteredMemes, setFilteredMemes] = useState(memes);
  const [displayedMemes, setDisplayedMemes] = useState([]);
  const [selectedMeme, setSelectedMeme] = useState(null);
  const [hasMore, setHasMore] = useState(true);

  const memesPerPage = 2000;

  useEffect(() => {
    filterMemes();
  }, [selectedCategory]);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (selectedMeme) {
        if (event.key === "ArrowRight") {
          navigateMeme("next");
        } else if (event.key === "ArrowLeft") {
          navigateMeme("previous");
        }
      }
    };
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [selectedMeme]);

  const filterMemes = () => {
    let filtered;
    if (selectedCategory === "template") {
      filtered = templates;
    } else if (selectedCategory === "branding") {
      filtered = branding;
    } else {
      filtered = memes.filter((meme) =>
        meme.category.includes(memeCategories[selectedCategory])
      );
    }

    setFilteredMemes(filtered);
    setDisplayedMemes(filtered.slice(0, memesPerPage));
    setHasMore(filtered.length > memesPerPage);
  };

  const loadMoreMemes = () => {
    if (filteredMemes.length > displayedMemes.length) {
      const newMemes = filteredMemes.slice(
        displayedMemes.length,
        displayedMemes.length + memesPerPage
      );
      setDisplayedMemes([...displayedMemes, ...newMemes]);
    } else {
      setHasMore(false);
    }
  };

  const handleCategoryChange = (category) => {
    setSelectedCategory(category);
  };

  const handleMemeClick = (meme) => {
    setSelectedMeme(meme);
  };

  const closeModal = () => {
    setSelectedMeme(null);
  };

  const handleDownloadClick = (id) => {
    const url =
      selectedCategory === "template"
        ? `https://storage.googleapis.com/pepeonblast/png-templates/${id}.png`
        : selectedCategory === "branding"
        ? `https://storage.googleapis.com/pepeonblast/png-branding/${id}.png`
        : `https://storage.googleapis.com/pepeonblast/png-memes/pepeonblast_${id}.png`;
    window.open(url, "_blank");
  };

  const preloadImages = (memes) => {
    memes.forEach((meme) => {
      const frontImage = new Image();
      frontImage.src = meme.front;
    });
  };

  const navigateMeme = (direction) => {
    if (selectedMeme) {
      const currentIndex = filteredMemes.findIndex(
        (meme) => meme.id === selectedMeme.id
      );
      let newIndex;
      if (direction === "next") {
        newIndex = (currentIndex + 1) % filteredMemes.length;
      } else if (direction === "previous") {
        newIndex =
          (currentIndex - 1 + filteredMemes.length) % filteredMemes.length;
      }
      setSelectedMeme(filteredMemes[newIndex]);
    }
  };

  useEffect(() => {
    if (filteredMemes.length > displayedMemes.length) {
      preloadImages(
        filteredMemes.slice(
          displayedMemes.length,
          displayedMemes.length + memesPerPage
        )
      );
    }
  }, [filteredMemes, displayedMemes]);

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(
      () => {
        alert(`Copied to clipboard: ${text}`);
      },
      (err) => {
        console.error("Could not copy text: ", err);
      }
    );
  };

  return (
    <div className="hall-of-memes">
      <div className="memes-title" id="memes-title">
        <span>pepe</span>
        <span className="default-font">&nbsp;</span>
        <span>on</span>
        <span className="default-font">&nbsp;</span>
        <span>blast</span>
        <span className="default-font">&nbsp;</span>
        <span>memeland</span>
      </div>
      <div className="controls">
        {Object.keys(memeCategories).map((category) => (
          <button
            key={category}
            className={`filter-button ${
              selectedCategory === category ? "selected" : ""
            }`}
            onClick={() => handleCategoryChange(category)}
          >
            {category}
          </button>
        ))}
      </div>
      <InfiniteScroll
        dataLength={displayedMemes.length}
        next={loadMoreMemes}
        hasMore={hasMore}
        loader={<h4>Loading...</h4>}
        className="meme-grid"
      >
        {displayedMemes.map((meme, index) => (
          <div
            key={meme.id}
            className="meme-card"
            onClick={() => handleMemeClick(meme)}
          >
            <img src={meme.front} alt={`Meme ${meme.id}`} />
          </div>
        ))}
      </InfiniteScroll>
      {selectedMeme && (
        <div className="modal" onClick={closeModal}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <span className="close-button" onClick={closeModal}>
              &times;
            </span>
            <div className="modal-images">
              <img src={selectedMeme.front} alt="Meme" />
              <div
                className="download-button"
                onClick={() => handleDownloadClick(selectedMeme.id)}
              ></div>
            </div>
          </div>
        </div>
      )}
      <Footer />
      <div className="branding-footer">
        <div
          className="branding-footer-element"
          onClick={() => copyToClipboard("#ffbb01")}
        >
          Main Color: <span className="main-color">#ffbb01</span>
        </div>
        <div
          className="branding-footer-element"
          onClick={() => copyToClipboard("#ffe9ae")}
        >
          Secondary Color: <span className="secondary-color">#ffe9ae</span>
        </div>
        <div className="branding-footer-element">
          <a
            href="https://www.dafont.com/kg-happy.font"
            target="_blank"
            rel="noopener noreferrer"
          >
            Main Font: <span className="main-color">KGHappy</span>
          </a>
        </div>
        <div className="branding-footer-element">
          <a
            href="https://www.dafont.com/kg-happy.font"
            target="_blank"
            rel="noopener noreferrer"
          >
            Secondary Font: <span className="main-color">KGHappySolid</span>
          </a>
        </div>
      </div>
    </div>
  );
};

export default Memes;
